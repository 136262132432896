// CITA 2: Siguiente codigo adaptado de
// https://codedamn.com/news/reactjs/how-to-connect-react-with-node-js

import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [num_ops_recibidas, setNumOpsRecibidas] = useState(0);
  const [desglose, setDesglose] = useState({});
  const [conciliacion, setConciliacion] = useState([]);
  const [tabla, setTabla] = useState([]);

  useEffect(() => {
    fetch("https://tarea-3-dicampusano-backend2.onrender.com/",{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then((res) => res.json())
      .then((data) => {
        setNumOpsRecibidas(data.num_ops_recibidas)
        setDesglose(data.desglose)
        setConciliacion(data.conciliacion)
        setTabla(data.tabla)});
  }, []);

  return (
    <div className="App">
      <h1>TAREA 3 DE DIEGO CAMPUSANO PARA IIC3103</h1>
      <h1>Cantidad de operaciones recibidas: {num_ops_recibidas}</h1>
      <h2>Cantidad de operaciones de envío de fondos: {desglose.num_ops_envio}</h2>
      <h2>Cantidad de operaciones de reversas de transacción: {desglose.num_ops_reversa}</h2>
      <h2>Monto total de operaciones de envío de fondos: {desglose.monto_envio}</h2>
      <h2>Monto total de operaciones de reversas de transacción: {desglose.monto_reversa}</h2>
    </div>
  );
}

export default App

// FIN CITA 2